<template>
  <router-header :routerHeaderInfo="routerHeaderInfo"></router-header>

  <a-form ref="formRef"
          :model="codeForm"
          :rules="rules"
          :labelCol="{style: 'width: 120px'}"
          :wrapperCol="{span: 8}">

    <p class="title-mark">基础信息</p>

    <a-form-item label="活码名称"
                 name="name">
      <a-input v-model:value="codeForm.name"
               placeholder="请输入活码名称" />
    </a-form-item>

    <a-form-item label="选择分组"
                 name="groupId">
      <a-select v-model:value="codeForm.groupId"
                placeholder="请选择活码分组">
        <a-select-option v-for="group in codeGroup"
                         :key="group.id">
          {{ group.name }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="使用成员"
                 name="staffList"
                 :wrapperCol="{span: 10}">

      <a-button class="text-color-999 hover:text-admin-primary"
                @click="selectStaffModalVisible = true">
        <PlusOutlined />添加成员
      </a-button>
      <span class="inline-block ml-8 text-14 text-color-999 whitespace-nowrap">
        同一个二维码可选择多位客服成员进行接待，客户扫码后随机分配一名客服人员
      </span>

      <div class="flex flex-wrap overflow-y-auto"
           style="max-height: 300px;">
        <div v-for="(staff, index) in staffNameList"
             :key="index"
             class="relative mt-8 mr-12 px-8 w-80 h-36 text-color-333 text-center line-clamp-1 bg-color-f5 select-none">
          <div v-is="'ww-open-data'"
               class="ww-open-data"
               type="userName"
               :openid="staff.workWechatThirdAppUserId"></div>
          <CloseCircleOutlined class="absolute top-0 right-0 text-icon hover:text-admin-primary"
                               @click="deleteOneStaff(index)" />
        </div>
      </div>

      <select-staff-modal v-model:visible="selectStaffModalVisible"
                          :defaultCheckedKeys="checkedKeys"
                          @saveStaff="saveStaff">
      </select-staff-modal>
    </a-form-item>

    <a-form-item label="客户标签"
                 name="tagList">
      <a-select v-model:value="codeForm.tagList"
                placeholder="请选择客户标签"
                :showSearch="false"
                showArrow
                mode="multiple">
        <a-select-opt-group v-for="corpTagGroup in corpTagList"
                            :key="corpTagGroup.workWechatThirdAppTagGroupId"
                            :label="corpTagGroup.name">
          <a-select-option v-for="tag in corpTagGroup.corpTagList"
                           :key="tag.workWechatThirdAppTagId">
            {{ tag.name }}
          </a-select-option>
        </a-select-opt-group>
      </a-select>
    </a-form-item>

    <div class="relative">
      <p class="title-mark mb-12">欢迎语</p>

      <div class="ml-32 mb-12 p-8 w-max text-14 text-color-999 border-radius bg-admin-primary bg-opacity-10">
        <svg-icon class="text-admin-primary mr-4"
                  type="icontishi1" />
        因企业微信限制，若使用成员已在「企业微信后台」配置了欢迎语，这里的欢迎语将不会生效.
      </div>

      <a-form-item label="设置欢迎语"
                   :name="['welcomeSpeech', 'enabled']">
        <a-radio-group v-model:value="codeForm.welcomeSpeech.enabled">
          <a-radio :value="1">开启</a-radio>
          <a-radio class="ml-44"
                   :value="0">关闭</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item v-if="codeForm.welcomeSpeech.enabled"
                   label="消息内容"
                   :name="['welcomeSpeech', 'content']">

        <div class="px-16 pb-24 border border-radius bg-color-f5"
             ref="welcomecontentRef">
          <a-button class="mr-24 mb-12 p-0 h-20 text-warning "
                    type="link"
                    @click="insert('客户昵称')">[插入客户昵称]</a-button>
          <!-- <a-button class="mr-24 mb-12 p-0 h-20 text-warning "
                    type="link"
                    @click="insert('员工姓名')">[插入员工姓名]</a-button> -->

          <div ref="contentRef"
               class="text-14 text-color-333 border-radius bg-color-f overflow-y-auto outline-none"
               style="width: 100%; height: 200px;"
               contenteditable="plaintext-only"
               @input="setMessageContent($event)"></div>

          <p class="mb-8 text-right">{{ messageLength || 0 }}/600</p>

          <div v-for="(content, index) in codeForm.welcomeSpeech.contents"
               :key="index"
               class="upload-item flex justify-between select-none"
               :class="{'border-top': index !== 0}">

            <template v-if="content.type === 'img' ">
              <span class="w-4/5 line-clamp-1 break-all">[图片]: <a-tooltip :title="content.imageName">
                  {{ content.imageName }}
                </a-tooltip>
              </span>
            </template>
            <template v-else-if="content.type === 'link' ">
              <span class="w-4/5 line-clamp-1 break-all">[链接]: <a-tooltip :title="content.link">
                  {{ content.link }}
                </a-tooltip></span>
            </template>
            <template v-else-if="content.type === 'file' ">
              <span class="w-4/5 line-clamp-1 break-all">[PDF]: <a-tooltip :title="content.name">
                  {{ content.name }}
                </a-tooltip>
              </span>
            </template>

            <div class="upload-icons">
              <svg-icon class="mr-12 text-icon text-16 cursor-pointer hover:text-admin-primary"
                        type="iconshanchu"
                        @click="deleteAppendix(index)" />
              <svg-icon class="text-icon text-16 cursor-pointer hover:text-admin-primary"
                        type="iconbianji"
                        @click="editAppendix(content, index)" />
            </div>
          </div>

          <a-button class="text-admin-primary border-admin-primary border-radius"
                    @click="addAppendix">
            <PlusOutlined />
            添加附件
          </a-button>

          <span class="ml-16 text-color-999">最多可以上传9个附件</span>

          <a-modal v-model:visible="addAppendixModalVisible"
                   :getContainer="() => $refs.welcomecontentRef"
                   width="600px"
                   title="添加附件"
                   @ok="saveContent"
                   @cancel="clearContent">
            <a-tabs v-model:activeKey="appendix.content.type">
              <a-tab-pane key="img"
                          tab="添加图片">
                <upload-image :apiName="'channelQrcode'"
                              :imageUrl="appendix.content.bucketUri"
                              @child-image="handleImgUrl($event)">
                </upload-image>
              </a-tab-pane>

              <a-tab-pane key="link"
                          tab="添加链接"
                          force-render>
                <a-form :model="appendix.content"
                        :label-col="{style: 'width: 72px'}"
                        :wrapper-col="{span: 18}">
                  <a-form-item name="link"
                               :wrapper-col="{span: 22}">
                    <a-input v-model:value="appendix.content.link"
                             placeholder="链接地址请以http:// 或 https://开头"
                             @keyup="getLinkInfo()" />
                  </a-form-item>

                  <a-form-item label="链接标题"
                               name="title"
                               :wrapper-col="{span: 19}">
                    <a-input v-model:value="appendix.content.title"
                             placeholder="请输入链接标题" />
                  </a-form-item>
                  <a-form-item label="链接摘要"
                               name="abstract"
                               :wrapper-col="{span: 19}">
                    <a-input v-model:value="appendix.content.summary"
                             placeholder="请输入链接摘要" />
                  </a-form-item>
                  <a-form-item label="链接封面">
                    <upload-image :imageUrl="appendix.content.cover"
                                  :apiName="'channelQrcodeLink'"
                                  @child-image="handleLinkImg">
                    </upload-image>
                  </a-form-item>
                </a-form>
              </a-tab-pane>

              <a-tab-pane key="file"
                          tab="添加PDF">
                <upload-file apiName='channelQrcode'
                             :fileInfo="appendix.content"
                             @child-image="handleFileUpload">
                </upload-file>
              </a-tab-pane>
            </a-tabs>
          </a-modal>
        </div>
      </a-form-item>

      <div v-if="codeForm.welcomeSpeech.enabled"
           class="absolute top-24 flex flex-col justify-center"
           style="right: 5%;">
        <p class="mb-28 font-medium text-16 text-center">客户收到的消息</p>
        <phone-box>
          <li v-if="messageInnerHTML"
              class="reply-list__item">
            <svg-icon class="icon-default-avatar"
                      type="iconmorentouxiang" />
            <div v-html="messageInnerHTML"
                 class="msg-text">
            </div>
          </li>
          <li v-for="(content, index) in codeForm.welcomeSpeech.contents"
              :key="index"
              class="reply-list__item">
            <svg-icon class="icon-default-avatar"
                      type="iconmorentouxiang"></svg-icon>
            <template v-if="content.type === 'img'">
              <img class="msg-img"
                   :src="content.bucketUri"
                   alt="渠道活码欢迎语附件图">
            </template>
            <template v-else-if="content.type === 'link'">
              <msg-link :msg="{title: content.title, subTitle: content.summary, img: content.cover}"></msg-link>
            </template>
            <template v-else-if="content.type === 'file'">
              <msg-link :msg="{title: content.name, subTitle: renderSize(content.size), img: pdfImgUrl}">
              </msg-link>
            </template>
          </li>
        </phone-box>
      </div>
    </div>
    <div class="save-btn mx-auto"
         style="margin-top: 250px;">
      <a-button class="btn"
                type="primary"
                :loading="btnLoading"
                @click="onSubmit">
        生成活码
      </a-button>
    </div>
  </a-form>
</template>

<script>
import { defineComponent, ref, reactive } from "vue";
import _ from "lodash";
import { Radio, Tabs, message, Tooltip } from "ant-design-vue";
import { useRouter } from "vue-router";

import CorpTag from "@/service/api/corpLag.js";
import ChannelQrcodeApi from "@/service/api/channelQrcode";
import ChannelQrcodeGroup from "@/service/api/channelQrcodeGroup";
import { renderSize } from "@/global";

import RouterHeader from "@/components/RouterHeader.vue";
import selectStaffModal from "@/components/SelectStaffByGroup.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import UploadImage from "@/components/UploadImage.vue";
import UploadFile from "@/components/UploadFile.vue";
import PhoneBox from "@/components/PhoneBox";
import MsgLink from "@/components/MsgLink";

export default defineComponent({
  name: "CreateLiveCode",

  components: {
    RouterHeader,
    selectStaffModal,
    SvgIcon,
    UploadImage,
    UploadFile,
    PhoneBox,
    MsgLink,

    ARadio: Radio,
    ARadioGroup: Radio.Group,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ATooltip: Tooltip,
  },

  setup() {
    const pdfImgUrl = process.env.VUE_APP_PDF_IMAGE_URL;
    const routerHeaderInfo = [
      {
        path: "/conduction",
        name: "渠道活码",
      },
      {
        name: "新建活码",
      },
    ];

    const corpTagList = ref();
    const codeGroup = ref();
    async function getTagList() {
      corpTagList.value = await CorpTag.getList();
    }
    async function getGroup() {
      codeGroup.value = await ChannelQrcodeGroup.getList();
    }

    const addAppendixModalVisible = ref(false);
    const welcomecontentRef = ref();

    const appendix = reactive({
      content: {
        type: "img",
      },
    });
    function addAppendix() {
      if (codeForm.welcomeSpeech.contents.length >= 9) {
        message.error("最多可以上传9个附件");
        return;
      }

      appendix.content = { type: "img" };
      addAppendixModalVisible.value = true;
    }
    function deleteAppendix(index) {
      codeForm.welcomeSpeech.contents.splice(index, 1);
    }
    function editAppendix(content, index) {
      appendix.content = _.assign({}, content);
      appendix.content.index = index;

      addAppendixModalVisible.value = true;
    }

    function clearContent() {
      appendix.content = {
        type: "img",
      };
      addAppendixModalVisible.value = false;
    }

    async function saveContent() {
      if (!validateContent()) {
        return;
      }

      const res = _.cloneDeep(appendix.content);
      if (res.index >= 0) {
        codeForm.welcomeSpeech.contents[res.index] = res;
      } else {
        await codeForm.welcomeSpeech.contents.push(res);
      }
      appendix.content = { type: "img" };
      addAppendixModalVisible.value = false;
    }

    function validateContent() {
      let validate = true;
      let errorcontent = "";
      if (appendix.content.type === "img") {
        validate = appendix.content.workWechatMediaId;
        errorcontent = "请选择图片";
      } else if (appendix.content.type === "link") {
        validate = validateLink()[0];
        errorcontent = validateLink()[1];
      } else if (appendix.content.type === "file") {
        validate = appendix.content.no;
        errorcontent = "请选择PDF文件";
      }

      if (!validate) {
        message.error(errorcontent);
      }

      return validate;
    }

    function validateLink() {
      const flag = !!(
        appendix.content.link &&
        appendix.content.title &&
        appendix.content.summary &&
        appendix.content.cover
      );

      return [flag, "请完善链接内容"];
    }

    const messageInnerHTML = ref("");
    const messageLength = ref("");

    function computedLength() {
      let length = 0;
      if (!contentRef.value) {
        return 0;
      }
      const childNodes = contentRef.value.childNodes;
      _.forEach(childNodes, (node) => {
        if (node.nodeName === "#text") {
          length += node.data.length;
        }
      });
      messageLength.value = length;
    }

    function insertHtml(html) {
      var sel, range;
      sel = window.getSelection();
      if (
        sel.focusNode !== contentRef.value &&
        sel.focusNode?.parentElement !== contentRef.value
      ) {
        setFocus(contentRef.value);
      }

      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();
        var el = document.createElement("div");
        el.innerHTML = html;
        var frag = document.createDocumentFragment(),
          node,
          lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);
        // Preserve the selection
        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(lastNode);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
    }

    async function insert(value) {
      insertHtml(
        `<span contenteditable="false" class="text-keyword">${value}</span>`
      );
      computedLength();
      messageInnerHTML.value = contentRef.value.innerHTML;
      codeForm.welcomeSpeech.content = contentRef.value.textContent;
    }

    document.onkeydown = (event) => {
      var key = window.event ? event.keyCode : event.which;
      const ENTER_KEY = "13";
      const DELETE_KEY = "8";
      if (key.toString() == ENTER_KEY) {
        insertHtml("<br /><br />");
        return false;
      }

      if (
        key.toString() === DELETE_KEY &&
        contentRef.value.lastChild.nodeName === "SPAN"
      ) {
        contentRef.value.removeChild(contentRef.value.lastChild);
        return;
      }
    };

    const contentRef = ref();
    const setMessageContent = _.debounce((e) => {
      messageInnerHTML.value = e.target.innerHTML;
      codeForm.welcomeSpeech.content = e.target.textContent;
      computedLength();

      if (messageLength.value > 600) {
        const length = contentRef.value.childNodes.length;
        findLastTextNode(contentRef.value.childNodes[length - 1]);

        setFocus(contentRef.value);
        messageInnerHTML.value = contentRef.value.innerHTML;
        codeForm.welcomeSpeech.content = contentRef.value.textContent;

        computedLength();
      }
    }, 500);
    function setFocus(el) {
      el.focus();
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      var sel = window.getSelection();
      //判断光标位置，如不需要可删除
      if (sel.anchorOffset != 0) {
        return;
      }
      sel.removeAllRanges();
      sel.addRange(range);
    }
    function findLastTextNode(node) {
      const TEXT_NODE = 3;
      if (node.nodeType === TEXT_NODE && node.nodeValue) {
        node.nodeValue = node.nodeValue.slice(0, 600 - messageLength.value);
        return;
      }
      findLastTextNode(node.previousSibling);
    }

    const btnLoading = ref(false);
    const codeForm = reactive({
      welcomeSpeech: {
        enabled: 1,
        contents: [],
        content: "",
      },
    });
    const rules = {
      name: [
        {
          required: true,
          message: "请输入活码名称",
          trigger: "blur",
          whitespace: true,
        },
        {
          min: 1,
          max: 30,
          message: "只能输入1-30个字符",
          trigger: ["change", "blur"],
        },
      ],
      groupId: [
        {
          required: true,
          message: "请选择分组",
          type: "number",
          trigger: "change",
        },
      ],
      staffList: [
        {
          validator: (rule, value) => {
            if (_.keys(value).length) {
              return Promise.resolve();
            }
            return Promise.reject("请选择成员");
          },
        },
      ],
      welcomeSpeech: {
        content: [
          {
            required: true,
            message: "请输入欢迎语消息内容",
            trigger: "blur",
            whitespace: true,
          },
        ],
      },
    };

    const formRef = ref();
    const router = new useRouter();

    function formatPlaceholder() {
      codeForm.welcomeSpeech.content = _.replace(
        messageInnerHTML.value,
        /<span[^>]*>客户昵称[^<]*?<\/span>/g,
        "{nickName}"
      );
      codeForm.welcomeSpeech.content = _.replace(
        codeForm.welcomeSpeech.content,
        /<span[^>]*>员工姓名[^<]*?<\/span>/g,
        "{staffName}"
      );
    }
    function formatEncode() {
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&" };

      codeForm.welcomeSpeech.content = _.replace(
        codeForm.welcomeSpeech.content,
        /&(lt|gt|nbsp|amp);/gi,
        function (all, t) {
          return arrEntities[t];
        }
      );
    }

    async function onSubmit() {
      formatPlaceholder();
      formatEncode();

      formRef.value
        .validate()
        .then(async () => {
          btnLoading.value = true;
          await ChannelQrcodeApi.create(codeForm);
          message.success("新建成功");
          router.push({ path: "conduction" });
        })
        .catch((err) => {
          console.log(err);
          btnLoading.value = false;
        });
    }

    getTagList();
    getGroup();
    return {
      pdfImgUrl,
      routerHeaderInfo,
      renderSize,

      corpTagList,
      codeGroup,

      addAppendixModalVisible,
      welcomecontentRef,
      appendix,

      addAppendix,
      deleteAppendix,
      editAppendix,

      saveContent,
      clearContent,
      setMessageContent,
      messageInnerHTML,

      insert,

      btnLoading,
      codeForm,
      rules,
      formRef,
      onSubmit,
      contentRef,
      messageLength,

      ...useSelectStaff(codeForm),
      ...useUpload(appendix),
    };
  },
});

function useSelectStaff(codeForm) {
  const selectStaffModalVisible = ref(false);
  const staffNameList = ref([]);
  const checkedKeys = ref([]);

  function saveStaff(checkedStaffs) {
    staffNameList.value = checkedStaffs;

    checkedKeys.value = [];
    codeForm.staffList = [];
    _.forEach(checkedStaffs, (staff) => {
      checkedKeys.value.push(staff.staffId);
      const splitId = _.split(staff.staffId, "_");
      codeForm.staffList.push(Number(splitId[1]));
    });

    selectStaffModalVisible.value = false;
  }
  function deleteOneStaff(index) {
    if (index <= -1 || index >= staffNameList.value.length) {
      return;
    }

    staffNameList.value.splice(index, 1);
    checkedKeys.value.splice(index, 1);
    codeForm.staffList.splice(index, 1);
  }

  return {
    selectStaffModalVisible,
    staffNameList,
    checkedKeys,
    saveStaff,
    deleteOneStaff,
  };
}

function useUpload(appendix) {
  function handleImgUrl(event) {
    appendix.content = _.assign(
      { type: "img", index: appendix.content.index },
      event
    );
  }

  function handleLinkImg(link) {
    appendix.content.cover = link;
  }

  function handleFileUpload(event) {
    appendix.content = _.assign(
      { type: "file", index: appendix.content.index },
      event
    );
  }

  const getLinkInfo = _.debounce(async () => {
    if (!/^(http|https):/.test(appendix.content.link)) {
      appendix.content.link = "https://" + appendix.content.link;
    }

    let urlInfo = await ChannelQrcodeApi.getUrlBaseInfo({
      url: appendix.content.link,
    });

    if (urlInfo.title?.length > 40) {
      urlInfo.title = urlInfo.title.slice(0, 40);
    }

    urlInfo.summary = urlInfo.desc;

    appendix.content = _.assign(
      {
        type: "link",
        link: appendix.content.link,
        index: appendix.content.index,
      },
      urlInfo
    );
  }, 800);

  return {
    handleImgUrl,
    handleLinkImg,
    handleFileUpload,
    getLinkInfo,
  };
}
</script>

<style lang='less' scoped>
.upload-item {
  .upload-icons {
    display: none;
  }

  &:hover {
    .upload-icons {
      display: unset;
    }
  }
}
</style>