<template>
  <div class="msg-link" :class="{'white-bg': whiteBg}">
    <div class="link-text">
      <p class="title">{{msg.title}}</p>
      <p class="abstract">{{msg.subTitle}}</p>
    </div>
    <img v-if="msg.img" class="link-img" :src="msg.img" alt="链接封面">
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "MsgLink",
  props: {
    msg: Object,
    whiteBg: Boolean,
  },
  emits: [],
  components: {},
});
</script>
<style lang='less' scoped>
.white-bg {
  background-color: @bg;
}
</style>