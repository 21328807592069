import {apiClient as client} from '@/shared/service/api-client';

export default {
    async getList() {
        return await client.get('/channelQrcodeGroup/getList');
    },
    async create(params) {
        return await client.post('/channelQrcodeGroup/create', params);
    },
    async delete(id) {
        return await client.get('/channelQrcodeGroup/delete', {params: {id: id}});
    },
    async get(id) {
        return await client.get('/channelQrcodeGroup/get', {params: {id: id}});
    },
    async update(params) {
        return await client.post('/channelQrcodeGroup/update', params);
    },
}
